<template>
  <div class="relative pb-6 pl-8">

    <!-- Horizontal labels -->
    <div class="absolute inset-x-0 bottom-0 ml-8 leading-none text-xs text-prasset-gray-800">
      <div v-for="(l, i) in xAxis" :key="`x_label_${i}`"
        class="absolute whitespace-no-wrap"
        :style="`bottom: 0; left: ${l.x}%; transform: translateX(${ i === 0 ? 0 : ( i === xAxis.length - 1 ? '-100' : '-50') }%);`"
      >
        {{ l.label }}
      </div>
    </div>

    <!-- Vertical labels -->
    <div class="absolute inset-y-0 left-0 mb-6 leading-none text-xs text-prasset-gray-800">
      <div v-for="(l, i) in yAxis" :key="`y_label_${i}`" class="absolute text-right w-5" :style="`left: 0; bottom: ${l.y}%; transform: translateY(50%);`">
        {{ l.label }}
      </div>
    </div>

    <div ref="plotarea" class="relative h-full w-full" @mousemove="onMouseMove" @mouseleave="onMouseLeave">

      <LoadingIndicator v-if="loading" class="text-gray-400 absolute" style="top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%)" />

      <!-- Data Points -->
      <div v-for="graph in graphs" :key="graph.id">
        <div v-for="(point, i) in graph.points" :key="`${graph.id}_${point.x}_${point.y}`" class="absolute" :style="`left: ${point.x}%; top: ${point.y}%;`">
          <div
            class="w-2 h-2 rounded-full bg-white white transition-transform duration-200"
            :style="`transform: translateX(-50%) translateY(-50%) scale(${i === hoverXAxis ? 1.5 : 1.1}); border: 1px solid rgb(${graph.dataset.color})`"
          />
          <div
            class="bg-prasset-green-400 leading-snug text-white min-w-4 rounded-full text-xs text-center transition-opacity opacity-0 duration-200"
            :class="{ 'opacity-100': i === hoverXAxis }"
            style="transform: translateY(-125%) translateX(8px);">
            {{ point.value }}
          </div>
        </div>
      </div>

      <!-- Graph -->
      <svg class="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none">

        <!-- Bounds -->
        <rect
          width="100%"
          height="100%"
          fill="none"
          vector-effect="non-scaling-stroke"
          class="stroke-current text-gray-300"
        />

        <!-- Horizontal grid lines  -->
        <polyline
          v-for="(line, i) in yAxis"
          :key="`line_y_${i}`"
          :points="`0,${line.y} 100,${line.y}`"
          fill="none"
          vector-effect="non-scaling-stroke"
          class="stroke-current text-gray-300"
        />

        <!-- Vertical grid lines  -->
        <polyline
          v-for="(line, i) in verticalLines"
          :key="`line_x_${i}`"
          :points="`${line.x},0 ${line.x},100`"
          fill="none"
          vector-effect="non-scaling-stroke"
          class="stroke-current"
          :class="{
            'text-gray-300': i !== hoverXAxis,
            'text-gray-500': i === hoverXAxis
          }"
        />

        <!-- Curved path -->
        <path
          v-for="graph in graphs"
          :key="graph.id"
          :d="graph.path"
          :stroke="`rgba(${graph.dataset.color})`"
          :fill="`rgba(${graph.dataset.color}, 0.3)`"
          vector-effect="non-scaling-stroke"
        />

      </svg>
    </div>
  </div>
</template>

<script>
import { generateUUID } from '@/providers/helpers';
import { computed, reactive, toRefs } from '@vue/composition-api';
import LoadingIndicator from '@/components/LoadingIndicator';

export default {
  name: 'LineChart',

  components: {
    LoadingIndicator,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    datasets: {
      type: Array,
      default: () => [
        // {
        //   data: [10, 90, 20, 50, 40, 100, 80],
        //   color: '7, 128, 128',
        // },
        // {
        //   data: [30, 20, 50, 60, 30, 111, 5],
        //   color: '128, 7, 128',
        // },
        // {
        //   data: [20, 44, 10, 20, 5, 20, 10],
        //   color: '50, 50, 255',
        // },
      ],
    },

    labels: {
      type: Array,
      default: () => [
        // '12-08', '12-09', '12-10', '12-11', '12-12', '12-13', '12-14',
      ],
    },
  },

  setup(props, { refs }) {

    const state = reactive({
      hoverXAxis: null,
    });

    /**
     * Compute minimum and maximum value of all datasets.
     */
    const valueBounds = computed(() => {
      let minValue = 0;
      let maxValue = 1;

      for (let i = 0, j = props.datasets.length; i < j; i++) {
        const data = props.datasets[i].data;

        if (!Array.isArray(data)) {
          throw new Error(`Data in a dataset must be of type array, ${typeof data} given`);
        }

        const min = Math.min(...data);
        const max = Math.max(...data);
        if (min < minValue) minValue = min;
        if (!maxValue || max > maxValue) maxValue = max;
      }

      return { min: minValue, max: maxValue };
    });

    const verticalLines = computed(() => {
      const spacing = 100 / (props.labels.length - 1);
      return props.labels.map((label, index) => {
        return {
          x: index * spacing,
        };
      });
    });

    const xAxis = computed(() => {
      const spacing = 100 / (props.labels.length - 1);
      return props.labels.map((label, index) => {
        return {
          label,
          x: index * spacing,
        };
      });
    });

    const yAxis = computed(() => {
      const { min, max } = valueBounds.value;

      const data = [
        { label: max, y: toPercentage(max, min, max) },
        { label: parseInt(max / 2), y: toPercentage(parseInt(max / 2), min, max) },
        { label: min, y: toPercentage(min, min, max) },
      ];

      if (min < 0) {
        data.push({ label: 0, y: toPercentage(0, min, max) });
      }

      return data;
    });

    // Value to percentage
    function toPercentage(value, min, max) {
      return ((value - min) * 100) / (max - min);
    }

    // Value to reverced percentage
    function toPercentageReversed(value, min, max) {
      return 100 - toPercentage(value, min, max);
    }

    function makePoints(dataset) {
      const spacing = 100 / (props.labels.length - 1);
      const { min, max } = valueBounds.value;
      return dataset.data.map((value, index) => {
        return {
          value,
          x: index * spacing,
          y: toPercentageReversed(value, min, max),
        };
      });
    }

    function makePath(data) {
      const smoothness = 4;
      const commands = ['M 0,100'];

      data.map((point, i) => {
        const previousPoint = data[i - 1];

        if (previousPoint) {
          commands.push(`C ${previousPoint.x + smoothness},${previousPoint.y} ${point.x - smoothness},${point.y}`);
        }

        commands.push(`${point.x}, ${point.y}`);
      });

      commands.push('T 100,100');

      return commands.join(' ');
    }

    const graphs = computed(() => {
      return props.datasets.map(dataset => {
        const points = makePoints(dataset);
        const path = makePath(points);

        return {
          id: generateUUID(),
          dataset,
          points,
          path,
        };
      });
    });

    function onMouseMove(event) {
      const rect = refs.plotarea.getBoundingClientRect();
      const x = Math.max(0, parseInt(event.clientX - rect.left));
      const percentageX = toPercentage(x, 0, rect.width);

      for (let i = 0, l = verticalLines.value.length; i < l; i++) {
        const vl = verticalLines.value[i];
        state.hoverXAxis = percentageX;

        if (percentageX >= vl.x - 5 && percentageX <= vl.x + 5) {
          state.hoverXAxis = i;
          break;
        }
      }
    }

    function onMouseLeave() {
      state.hoverXAxis = null;
    }

    return {
      ...toRefs(state),
      graphs,
      verticalLines,
      xAxis,
      yAxis,
      valueBounds,
      onMouseMove,
      onMouseLeave,
    };
  },
};

</script>
