import { DateTime } from 'luxon';
import { reactive, computed } from '@vue/composition-api';
import Api from '@/providers/api';

const state = reactive({
  project: null,
  updatedAt: null,
  websiteVisitors: [],
  websiteVisitorsLoading: false,
});

export default function useAnalytics(project_id) {

  // Clear all global data when switching projects
  if (state.project !== project_id) {
    state.project = project_id;
    state.websiteVisitors = [];
    state.updatedAt = null;
  }

  async function getAssetCountByContractTypeStats() {
    const response  = await Api().get(`projects/${project_id}/assets/statistics/count-by-contract-type`);
    return response.data.data;
  }

  async function loadWebsiteVisitors(days = 7) {

    // Data caching
    if (state.updatedAt && DateTime.local().diff(state.updatedAt).as('minutes') < 15) {
      return Promise.resolve();
    }

    state.websiteVisitorsLoading = true;

    const responses = [];
    const requests = [];

    for (let i = days - 1; i >= 0; i--) {
      const dt = DateTime.local().minus({ days: i }).toISODate();
      const request = Api().get(`analytics/${project_id}/website/visitors?period=day&date=${dt}`).then(response => {
        responses[days - 1 - i] = response.data;
      });
      requests.push(request);
    }

    await Promise.all(requests);

    state.websiteVisitors = responses;
    state.websiteVisitorsLoading = false;
    state.updatedAt = DateTime.local();
  }

  function getWebsiteVisitorsGraph(days = 7) {
    loadWebsiteVisitors(days);

    return {
      loading: computed(() => state.websiteVisitorsLoading),

      labels: computed(() => {
        return state.websiteVisitors.map(visitors => {
          return DateTime.fromISO(visitors.date).toFormat('d/M');
        });
      }),

      datasets: computed(() => [
        {
          color: '7, 128, 128',
          data: state.websiteVisitors.map(visitors => {
            return visitors.data.nb_visits;
          }),
        },
      ]),
    };
  }

  return {
    getAssetCountByContractTypeStats,
    loadWebsiteVisitors,
    getWebsiteVisitorsGraph,
  };
}
