<template>
  <div>
    <div class="layout m-4 grid grid-cols-1 lg:grid-cols-4 gap-4">
      <div class="box box-activeproject col-span-1 lg:col-span-4">
        <div class="relative z-2">
          <div class="font-medium text-2xl mb-4">{{ project.name }}</div>
          <div v-if="project.description" class="leading-loose max-w-102">{{ project.description }}</div>
        </div>
        <div v-if="projectImpression" class="absolute top-0 bottom-0 right-0 w-50 overflow-hidden">
          <div class="shade absolute inset-0"></div>
          <img :src="projectImpression" class="object-cover h-full w-full" />
        </div>
      </div>
      <div class="box col-span-1 lg:col-span-4 xl:col-span-2">
        <header class="box__header">
          <h3 class="font-medium">{{ $tuf('website_visitors_last_week') }}</h3>
        </header>
        <LineChart :datasets="visitors.datasets" :labels="visitors.labels" :loading="visitors.loading" class="h-72" />
      </div>
      <div class="box col-span-1 lg:col-span-2 xl:col-span-1">
        <header class="box__header">
          <h3 class="font-medium">{{ $tuf('assets') }}</h3>
          <!-- <div>
            <router-link :to="{ name: 'crm' }" class="text-prasset-red-500">{{ $tuf('go_to_crm') }}</router-link>
          </div> -->
        </header>
        <PieChart :values="piechart.values" :labels="piechart.labels" :loading="piechart.loading" class="h-72" />
      </div>
      <div class="box col-span-1 lg:col-span-2 xl:col-span-1">
        <header class="box__header">
          <h3 class="font-medium">{{ $tuf('recent_changed_assets')}}</h3>
          <div>
            <router-link :to="{ name: 'pim' }" class="text-prasset-red-500">{{ $tuf('go_to_pim') }}</router-link>
          </div>
        </header>
        <div v-if="recentChangedAssets.length > 0">
          <div v-for="asset in recentChangedAssets" :key="asset.id" class="border-b border-gray-200">
            <router-link :to="{ name: 'pim-view', params: { type: 'assets', entry: asset.id }}" class="truncate block hover:text-prasset-green-500 py-2">
              {{ asset.name || asset.id }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from '@vue/composition-api';
import { project, projects } from '@/compositions/useProjects';
import useAnalytics from '@/compositions/useAnalytics';
import useRecords from '@/compositions/useRecords';
import get from 'lodash/get';
import find from 'lodash/find';
import LineChart from '@/components/LineChart';
import PieChart from '@/components/PieChart';

export default {
  name: 'Dashboard',

  components: {
    LineChart,
    PieChart,
  },

  setup(props, { root }) {
    const { project_id } = root.$route.params;
    const { getWebsiteVisitorsGraph, getAssetCountByContractTypeStats } = useAnalytics(project_id);
    const { getRecords } = useRecords();

    const state = reactive({
      visitors: getWebsiteVisitorsGraph(),
      piechart: { loading:true },
      recentChangedAssets: [],
    });

    getRecords(`projects/${project_id}/assets`, 1, {
      per_page: 6, sort: '-updated_at',
    }).then(response => {
      state.recentChangedAssets = response.data.data;
    });

    getAssetCountByContractTypeStats().then(data => {
      state.piechart = data;
    });

    const otherProjects = computed(() => {
      return projects.value
        .filter(singleProject => {
          return singleProject.id !== project.id;
        })
        .slice(0, 5);
    });

    const projectLogo = computed(() => {
      const image = find(project.value.media, { collection: 'logo' });
      return get(image, 'urls.original');
    });

    const projectImpression = computed(() => {
      const image = find(project.value.media, { collection: 'main_image' });
      return get(image, 'urls.large');
    });

    return {
      ...toRefs(state),
      project,
      projectLogo,
      projectImpression,
      otherProjects,
      getWebsiteVisitorsGraph,
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  @apply .relative
         .min-h-32
         .bg-white
         .rounded-lg
         .p-4
         .shadow-lg;

  &__header {
    @apply .flex
           .justify-between
           .pb-4;
  }
}

.box-activeproject {
  @apply .text-white
         .p-10
         .bg-prasset-gray-800
         .bg-no-repeat
         .bg-right-top
         .min-h-84;
}
.shade {
  background-size: auto 100%;
  background-image: linear-gradient(to right, #30444E 50%, rgba(48, 68, 78, 0));
}

.box-projects {
  @apply .bg-prasset-green-500 .text-white;
  h3 {
    @apply .text-white;
  }
}
</style>
