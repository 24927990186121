import { reactive, toRefs } from '@vue/composition-api';
import Api, { isCanceled, cancelRequest, createCancelToken } from '@/providers/api';

export default function useRecords() {

  const state = reactive({
    data: null,
    meta: null,
    failed: false,
    loading: false,
    sorting: null,
    cancelToken: null,
  });

  function getRecords(endpoint, page, query = {}) {
    query = {
      page: page,
      per_page: 10,
      ...query,
    };

    if (state.sorting) {
      query.sort = `${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.column}`;
    }

    const queryString = Object.keys(query).map(key => {
      return [key, query[key]].join('=');
    }).join('&');

    const url = `${endpoint}?${queryString}`;

    cancelRequest(state.cancelToken);
    state.cancelToken = createCancelToken();

    return new Promise((resolve, reject) => {
      state.failed = false;
      state.loading = true;

      Api().get(url, { cancelToken: state.cancelToken.token })
        .then(response => {
          state.loading = false;
          state.data = response.data.data;
          state.meta = response.data.meta;
          resolve(response);
        })
        .catch(response => {
          if (!isCanceled(response)) {
            state.failed = true;
            reject(response);
          }
        });
    });
  }

  function deleteRecord(endpoint) {
    return new Promise((resolve, reject) => {
      Api().delete(endpoint).then(response => {
        resolve(response);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  }

  function setSorting(column, direction) {
    state.sorting = {
      column,
      direction,
    };
  }

  function resetRecords() {
    state.data = null;
    state.meta = null;
    state.sorting = null;
  }

  return {
    ...toRefs(state),
    resetRecords,
    setSorting,
    deleteRecord,
    getRecords,
  };
}
