<template>
  <div class="relative">
    <div ref="plotarea" class="relative h-full w-full">

      <LoadingIndicator v-if="loading" class="text-gray-400 absolute" style="top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%)" />

      <div
        v-for="(path, i) in paths" :key="i"
        class="absolute flex justify-end top-0 bottom-0 pointer-events-none"
        :style="`left: 50%; transform-origin: 50% 50%; transform: translateX(-50%) rotate(${path.center * 3.6}deg)`"
      >
        <div
          class="bg-white leading-snug text-prasset-gray-800 mt-6 rounded-full w-8 h-8 flex justify-center items-center text-xs shadow"
          :style="`transform: rotate(-${path.center * 3.6}deg`"
        >
          {{ Math.round(path.percentage) }}%
        </div>
      </div>

      <svg class="w-full h-full" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" fill="currentColor" class="text-gray-200"></circle>

        <circle v-if="paths.length === 1" cx="50" cy="50" r="50" fill="rgb(10, 110, 110)">
          <title>{{ paths[0].label }} {{ paths[0].value }} uit {{ totalValue }} ({{ Math.round(paths[0].percentage) }}%)</title>
        </circle>

        <template v-if="paths.length > 1">
          <path
            v-for="(path, i) in paths"
            :key="i"
            :d="drawPath(path.percentage)"
            class="ease-out"
            :style="`fill: rgba(${10*(i+1)}, 1${10*(i+1)}, 1${10*(i+1)}, 1); transition: 150ms transform; transform-origin: 50% 50%; transform: rotate(${path.start * 3.6}deg);`"
            @mouseenter="onMouseEnterPath($event, path)"
            @mouseleave="onMouseLeavePath($event, path)"
          >
            <title>{{ path.label }} {{ path.value }} uit {{ totalValue }} ({{ Math.round(path.percentage) }}%)</title>
          </path>
        </template>

        <circle cx="50" cy="50" r="25" fill="white"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
// :fill="`rgba(${10*(i+1)}, 1${10*(i+1)}, 1${10*(i+1)}, 1)`"
// :transform="`rotate(${path.start * 3.6} 50 50)`"
// https://robcrocombe.com/2018/08/25/labeling-pie-charts-without-collisions/
import { computed } from '@vue/composition-api';
import LoadingIndicator from '@/components/LoadingIndicator';

export default {
  name: 'PieChart',

  components: {
    LoadingIndicator,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    values: {
      type: Array,
      default: () => [
        // 1, 30, 20, 10, 33
      ],
    },

    labels: {
      type: Array,
      default: () => [
        // 'a','b','c','d','e'
      ],
    },
  },

  setup(props) {

    function toPercentage(value, min, max) {
      return ((value - min) * 100) / (max - min);
    }

    const segments = computed(() => {
      const sum = props.values.reduce((a, b) => a + b, 0);

      const values = props.values.map((value, i) => {
        const percentage = toPercentage(value, 0, sum);
        return {
          percentage,
          value,
          label: props.labels[i] || null,
        };
      });

      return [...values].sort((a,b) => b.value - a.value);
    });

    const totalValue = computed(() => {
      return props.values.reduce((a, b) => a + b, 0);
    });

    const paths = computed(() => {
      const res = segments.value.reduce((obj, segment) => {
        obj.segments.push({
          ...segment,
          start: obj.start,
          end: obj.start + segment.percentage,
          center: obj.start + ((obj.start + segment.percentage - obj.start) / 2),
        });

        obj.start += segment.percentage;

        return obj;
      }, { segments: [], start: 0 });

      return res.segments;
    });

    function drawPath(percent) {
      const start = 0;
      const size = 100;
      const rad  = size / 2;
      const unit = (Math.PI * 2) / 100;
      const end  = percent * unit + 0.002;
      const x1   = rad + rad * Math.sin(start);
      const y1   = rad - rad * Math.cos(start);
      const x2   = rad + rad * Math.sin(end);
      const y2   = rad - rad * Math.cos(end);
      const big  = (end - start > Math.PI) ? 1 : 0;
      return `M ${rad},${rad} L ${x1},${y1} A ${rad},${rad} 0 ${big} 1 ${x2},${y2} Z`;
    }

    function onMouseEnterPath(event, path) {
      event.target.style.transformOrigin = '50% 50%';
      event.target.style.transform = `scale(0.97) rotate(${path.start * 3.6}deg)`;
    }

    function onMouseLeavePath(event, path) {
      event.target.style.transformOrigin = '50% 50%';
      event.target.style.transform = `scale(1) rotate(${path.start * 3.6}deg)`;
    }

    return {
      segments,
      paths,
      drawPath,
      totalValue,
      onMouseEnterPath,
      onMouseLeavePath,
    };
  },
};
</script>
